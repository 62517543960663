// extracted by mini-css-extract-plugin
export var recentArticles = "ck_qB";
export var recentArticles__link = "ck_qD";
export var recentArticles__post = "ck_qC";
export var recentArticles__postAuthor = "ck_qM";
export var recentArticles__postAuthorImage = "ck_qL";
export var recentArticles__postAuthorName = "ck_qN";
export var recentArticles__postCategory = "ck_qG";
export var recentArticles__postDate = "ck_qR";
export var recentArticles__postDescriprion = "ck_qJ";
export var recentArticles__postImage = "ck_qF";
export var recentArticles__postInfo = "ck_qP";
export var recentArticles__postInfoWrapper = "ck_qK";
export var recentArticles__postStats = "ck_qQ";
export var recentArticles__postTitle = "ck_qH";
export var recentArticles__title = "ck_qS";